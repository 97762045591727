import React from "react";
import logo from '../assets/logos/yellow-white.png';

function Footer() {
    return (
        <div className='relative bg-primary-dark text-primary flex items-center justify-center text-lg md:text-xl w-full'>
        <div className='flex items-center justify-between w-full pl-6 pr-6 md:w-3/4'>
            <div className='pt-12 pb-12 text-center'>
                <a href="801-725-2552">801-725-2552</a>
                <p>2797 S 1900 W </p>
                <p>Ogden, UT</p>
                <p>84401</p>
            </div>
            <div className='pt-12 pb-12 text-center'>
                <p>Hours of Operation:</p>
                <p>M-F 9am-5pm</p>
                <p>Sat 8am-12pm</p>
                <p>Closed Sunday</p>
            </div>
            <div className="hidden md:inline">
                <img src={logo} alt="Greystone Logo" className="h-44" />
            </div>
        </div>
    </div>
    );
}

export default Footer;