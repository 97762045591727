import React, { useState } from "react";

function ReviewCarousel() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slides = [
        <div className="w-full md:w-3/4 text-lg">
            <p className="p-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.             
            </p>
            <p className="italic">-fake customer</p>
        </div>,
        <div className="w-full md:w-3/4 text-lg">
            <p className="p-2">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.            </p>
            <p className="italic">-fake customer</p>
        </div>,
        <div className="w-full md:w-3/4 text-lg">
            <p className="p-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.             
            </p>
            <p className="italic">-fake customer</p>
        </div>,
        <div className="w-full md:w-3/4 text-lg">
            <p className="p-2">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.            </p>
            <p className="italic">-fake customer</p>
        </div>
    ];

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };
    
    const goToSlide = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className="relative w-full md:w-7/12 mx-auto bg-white rounded-sm text-center p-6">
            <h2 className='text-4xl md:text-6xl pb-4 text-primary-dark'> Reviews</h2>
            <div className="overflow-hidden relative">
                <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {slides.map((slide, index) => (
                        <div key={index} className="min-w-full pb-12 flex flex-col items-center justify-center">
                            {slide}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <button onClick={() => goToSlide(0)} className={`w-4 h-4 m-0.5 rounded-full hover:bg-primary ${currentIndex === 0 ? 'bg-primary' : 'bg-gray-400 bg-opacity-20'}`}>
                        <span className="sr-only">Slide 1</span>
                </button>
                <button onClick={() => goToSlide(1)} className={`w-4 h-4 m-0.5 rounded-full hover:bg-primary ${currentIndex === 1 ? 'bg-primary' : 'bg-gray-400 bg-opacity-20'}`}>
                        <span className="sr-only">Slide 2</span>
                </button>
                <button onClick={() => goToSlide(2)} className={`w-4 h-4 m-0.5 rounded-full hover:bg-primary ${currentIndex === 2 ? 'bg-primary' : 'bg-gray-400 bg-opacity-20'}`}>
                        <span className="sr-only">Slide 2</span>
                </button>
                <button onClick={() => goToSlide(3)} className={`w-4 h-4 m-0.5 rounded-full hover:bg-primary ${currentIndex === 3 ? 'bg-primary' : 'bg-gray-400 bg-opacity-20'}`}>
                        <span className="sr-only">Slide 2</span>
                </button>
            </div>
        </div>
    );
}

export default ReviewCarousel;