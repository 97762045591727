import React from "react";

interface ActionButtonProps {
  text: string;
  onClick?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text, onClick }) => {
  return (
    <button 
      className="border-2 border-primary text-primary text-2xl p-2 rounded-sm bg-transparent hover:bg-primary hover:text-white"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default ActionButton;