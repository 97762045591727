import { createFileRoute } from '@tanstack/react-router'
import NavBar from '../components/NavBar'

export const Route = createFileRoute('/about')({
  component: About,
})

function About() {
  return (
    <div>
      <NavBar />
    </div>
  )
}
