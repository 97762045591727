import React from 'react';
import NavBar from './NavBar';
import '../App.css';
import hero from '../assets/images/hero.jpg';
import gravel from '../assets/images/gravel.jpg';
import logo from '../assets/logos/yellow-white.png';
import ActionButton from './ActionButton';
import ReviewCarousel from './ReviewCarousel';

function HomePage() {
    return (
        <div className="relative font-leagueSpartan bg-primary-light w-full h-full">
            <NavBar />
            <div style={{ backgroundImage: `url(${hero})` }} className='bg-cover bg-center mt-20 w-screen relative h-3/5 md:h-full'> 
                <div className='absolute top-0 left-0 w-full h-full bg-primary-dark bg-opacity-80 text-primary-light'>
                    <div className="ml-4 mr-4 mt-24 md:ml-32">
                        <h2 className="text-4xl md:text-6xl">Premium Landscaping <br /> Materials</h2>
                        <p className="text-lg md:text-3xl pt-8 md:pr-24 xl:w-1/2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
                        et dolore magna aliqua.
                        </p>
                        <p className="text-lg md:text-3xl pt-8 md:pr-24 xl:w-1/2">
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                        dolore eu fugiat nulla pariatur.
                        </p>
                    </div>
                </div>
                <div className='absolute hidden -bottom-24 md:inline md:-bottom-48 right-0 w-full -skew-y-3 bg-primary-light h-1/3'>
                </div>                
            </div>
            <div className='relative bg-primary-light flex flex-col items-center justify-center'>            
                <div className="bg-white text-center z-40 p-6 pt-12 pb-12 md:-mt-36 md:w-5/12 md:rounded-sm md:p-12">
                    <h2 className='text-4xl md:text-6xl pb-12 text-primary-dark'> Get your material ordered today.</h2>
                    <ActionButton text="Order Now" />
                </div>
                <div className='mt-32 mb-24 md:mb-96 w-full'>
                    <div className='flex flex-wrap items-center justify-center w-full text-3xl pl-2 pr-2 md:text-6xl md:pl-16 md:pr-16'>
                        <a className='flex items-center justify-center w-1/2 h-44 md:w-1/3 md:h-96 p-1' href="#">
                            <div className='relative bg-gravel bg-cover bg-no-repeat w-full h-full'>
                                <div className='absolute bg-primary-dark bg-opacity-50 w-full h-full hover:bg-primary hover:bg-opacity-50' />
                                <h2 className='absolute bottom-0 font-bold text-white ml-4'>GRAVEL</h2>
                            </div>
                        </a>
                        <a className='flex items-center justify-center w-1/2 h-44 md:w-1/3 md:h-96 p-1' href="#">
                            <div className='relative bg-slabs bg-cover bg-no-repeat w-full h-full'>
                                <div className='absolute bg-primary-dark bg-opacity-50 w-full h-full hover:bg-primary hover:bg-opacity-50' />
                                <h2 className='absolute bottom-0 font-bold text-white ml-4'>GRAVEL</h2>
                            </div>
                        </a>
                        <a className='flex items-center justify-center w-1/2 h-44 md:w-1/3 md:h-96 p-1' href="#">
                            <div className='relative bg-pillars bg-cover bg-no-repeat w-full h-full'>
                                <div className='absolute bg-primary-dark bg-opacity-50 w-full h-full hover:bg-primary hover:bg-opacity-50' />
                                <h2 className='absolute bottom-0 font-bold text-white ml-4'>GRAVEL</h2>
                            </div>
                        </a>
                        <a className='flex items-center justify-center w-1/2 h-44 md:w-1/3 md:h-96 p-1' href="#">
                            <div className='relative bg-pillars bg-cover bg-no-repeat w-full h-full'>
                                <div className='absolute bg-primary-dark bg-opacity-50 w-full h-full hover:bg-primary hover:bg-opacity-50' />
                                <h2 className='absolute bottom-0 font-bold text-white ml-4'>GRAVEL</h2>
                            </div>
                        </a>
                        <a className='flex items-center justify-center w-1/2 h-44 md:w-1/3 md:h-96 p-1' href="#">
                            <div className='relative bg-gravel bg-cover bg-no-repeat w-full h-full'>
                                <div className='absolute bg-primary-dark bg-opacity-50 w-full h-full hover:bg-primary hover:bg-opacity-50' />
                                <h2 className='absolute bottom-0 font-bold text-white ml-4'>GRAVEL</h2>
                            </div>
                        </a>
                        <a className='flex items-center justify-center w-1/2 h-44 md:w-1/3 md:h-96 p-1' href="#">
                            <div className='relative bg-slabs bg-cover bg-no-repeat w-full h-full'>
                                <div className='absolute bg-primary-dark bg-opacity-50 w-full h-full hover:bg-primary hover:bg-opacity-50' />
                                <h2 className='absolute bottom-0 font-bold text-white ml-4'>GRAVEL</h2>
                            </div>
                        </a>
                    </div>
                </div>
                <div className='absolute hidden -bottom-96 right-0 w-full skew-y-3 bg-primary-dark h-1/3 md:inline'>
                </div> 
                <ReviewCarousel />
            </div>
            <div className='relative bg-primary-dark md:mt-56 text-primary flex items-center justify-center text-lg md:text-xl w-full'>
                <div className='flex items-center justify-between w-full pl-6 pr-6 md:w-3/4'>
                    <div className='pt-12 pb-12 text-center'>
                        <a href="801-725-2552">801-725-2552</a>
                        <p>2797 S 1900 W </p>
                        <p>Ogden, UT</p>
                        <p>84401</p>
                    </div>
                    <div className='pt-12 pb-12 text-center'>
                        <p>Hours of Operation:</p>
                        <p>M-F 9am-5pm</p>
                        <p>Sat 8am-12pm</p>
                        <p>Closed Sunday</p>
                    </div>
                    <div className="hidden md:inline">
                        <img src={logo} alt="Greystone Logo" className="h-44" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;